import React, { useMemo, useState, useEffect, useRef, useCallback } from "react"
import { Transition, TransitionGroup } from "react-transition-group"
import { navigate } from "@reach/router"
import Cookies from "js-cookie"
import gsap from "gsap"
import DrawSVGPlugin from "gsap/DrawSVGPlugin"
import Logo from "../components/logo"
import Linkedin from "../components/linkedin"
import LanguageSwitcher from "../components/languageSwitcher"
import "../styles.scss"

gsap.registerPlugin(DrawSVGPlugin)

export default function Layout({ children, location }) {

  let lang = useRef("en")

  const [init, setInit] = useState(false)

  const logoRef = useRef(null)

  const logoAnimation = useMemo(() => {
    return gsap.timeline({ paused: true, delay: 0.5 })
  }, [])

  const maskRef = useRef(null)

  const go = useCallback(() => {

    if (init === true) {
      return;
    }

    logoAnimation.seek(logoAnimation.totalDuration())
    const tl = gsap.timeline({ paused: true })
    // const S = logoRef.current.querySelector("path.S")
    logoAnimation.pause()
    tl.to(logoRef.current, 0.5, { scale: 1 })
    // Layout height
    // tl.to(S, 0.3, { transformOrigin: "50% 50%", rotate: "-45short" })
    // tl.to(S, 0.3, { transformOrigin: "50% 50%", rotate: "0short" })
    document.body.style.overflow = "hidden"
    tl.call(() => {
      setInit(true)
    })
    tl.call(() => {
      setTimeout(() => {
        // Layout
        gsap.to(logoRef.current, 0.5, { top: 0 })
        gsap.set('.layoutGrid', { height: 'auto', overflowY: 'auto' })
        gsap.to(maskRef.current, 0.8, { top: "-102vh" })
        document.body.style.overflow = "auto"
      }, 55)
    })
    tl.play()
  }, [logoAnimation, init])

  useEffect(() => {
    const animateLogo = () => {
      logoAnimation.fromTo(
        logoRef.current.querySelector("path.S"),
        { drawSVG: "50% 50%" },
        { duration: 1.5, drawSVG: true }
      )
      logoAnimation.to(
        logoRef.current.querySelectorAll("path:not(.S)"),
        0.8,
        { fill: "#f23030", stroke: "none" },
        "-=0.3"
      )
      logoAnimation.to(
        logoRef.current.querySelector("path.S"),
        0.8,
        { fill: "#f23030", stroke: "#1a2641" },
        "<"
      )
      const tl = gsap.timeline({ repeat: -1, yoyo: true })
      tl.to(logoRef.current, 1.5, { scale: 1.3 })
      logoAnimation.add(tl, "<")
      navigate(`/${lang.current}`)
      logoAnimation.play()
    }

    const cookieLanguage = Cookies.get("siam-language")
    if (cookieLanguage) {
      lang.current = cookieLanguage
    } else {
      let navLang =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage
      if (navLang.match(/[a-z]{2}-[A-Z]{2}/)) {
        navLang = navLang.split("-")[0]
      }
      lang.current = navLang
    }
    animateLogo()
    // On attend 3 secondes et on entre
    setTimeout(() => {
      go()
    }, 7420)
  }, [logoAnimation, go])

  const animateEnter = node => {
    if (node === null) return
    gsap.fromTo(node, { opacity: 0 }, { duration: 0.3, opacity: 1, delay: 0.3 })
  }

  const animateExit = node => {
    if (node === null) return
    gsap.fromTo(node, { opacity: 1 }, { duration: 0.3, opacity: 0 })
  }

  return (
    <>
      <div className="layoutGrid">
        <div className="logoContainer">
          <Logo ref={logoRef} onClick={go} />
        </div>
        {init && <Linkedin lang={lang} />}
        {init && <LanguageSwitcher lang={lang} />}
        <TransitionGroup component={null}>
          <Transition
            key={location.pathname}
            appear
            timeout={0}
            onEnter={animateEnter}
            in
            onExit={animateExit}
          >
            {children}
          </Transition>
        </TransitionGroup>
        {init && <div className="address"><b>SIAM CAPITAL</b> rue des Bains 35, 1205 Genève</div>}
      </div>
      <div ref={maskRef} className="mask"></div>
    </>
  )
}
