import React from "react"
import { Link } from "gatsby"
import Cookies from "js-cookie"

export default function LanguageSwitcher({ lang }) {
  const setLang = (l) => {
    lang.current = l;
    Cookies.set('siam-language', l, { expires: 365 });
  }
  return (
    <div className="languageSwitcher">
      <Link onClick={() => setLang('en')} to="/en">en</Link>
      <Link onClick={() => setLang('fr')} to="/fr">fr</Link>
      <Link onClick={() => setLang('es')} to="/es">es</Link>
    </div>
  )
}
